import Awesomplete from "awesomplete";
import algoliasearch, { SearchIndex } from "algoliasearch/lite";

type AwesompleteHighlightEvent = {
  text: string;
};

type AlgoliaHit = {
  query: string;
  popularity: number;
};

const initAutoSuggest = (
  index: SearchIndex,
  awesomplete: Awesomplete,
  input: HTMLInputElement,
) => {
  const siteSearchBox = input;

  // populate the search bar with the result, leave clear otherwise
  document.addEventListener("DOMContentLoaded", () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("s")) {
      siteSearchBox.value = searchParams.get("s") as string;
    }
  });

  siteSearchBox.addEventListener("awesomplete-highlight", (event) => {
    siteSearchBox.value = (event as unknown as AwesompleteHighlightEvent).text;
  });

  siteSearchBox.addEventListener("awesomplete-selectcomplete", () => {
    const form = siteSearchBox.closest("form") as HTMLFormElement;
    form.submit();
  });

  siteSearchBox.addEventListener("input", async (event) => {
    const target = event.target as HTMLInputElement;
    try {
      const result = await index.search(target.value, { hitsPerPage: 7 });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-param-reassign
      awesomplete.list = result.hits as any[];
    } catch {
      console.error("Couldn't fetch search suggestions");
    }
  });
};

((
  algoliaApplicationId: string | undefined,
  algoliaPublicApiKey: string | undefined,
  algoliaIndexSuffix: string | undefined,
) => {
  if (
    algoliaApplicationId === undefined ||
    algoliaPublicApiKey === undefined ||
    algoliaIndexSuffix === undefined
  ) {
    console.error("Algolia Configuration Missing — query suggestions disabled");
    return;
  }

  const defaultIndexPrefix = "CommonIndex";
  const algoliaIndex = `${defaultIndexPrefix}_${algoliaIndexSuffix}`;
  const algoliaQuerySuggestionsIndex = `${algoliaIndex}_query_suggestions`;

  const client = algoliasearch(algoliaApplicationId, algoliaPublicApiKey);
  const index = client.initIndex(algoliaQuerySuggestionsIndex);

  const inputs = document.querySelectorAll<HTMLInputElement>(
    "#bn-site-search-box",
  );

  if (!inputs) {
    return;
  }

  inputs.forEach((input) => {
    const awesomplete = new Awesomplete(input, {
      minChars: 3,
      sort: (a, b) =>
        (a as unknown as AlgoliaHit).popularity -
        (b as unknown as AlgoliaHit).popularity,
      filter: () => true,
      data: (item) => (item as unknown as AlgoliaHit).query,
    });

    initAutoSuggest(index, awesomplete, input);
  });
})(
  process.env.ALGOLIA_APPLICATION_ID,
  process.env.ALGOLIA_PUBLIC_API_KEY,
  process.env.ALGOLIA_INDEX_SUFFIX,
);
